// src/components/ArticleCard.js
import React from 'react';
import '../styles/ArticleCard.css'; // Custom styles for additional tweaks

const ArticleCard = ({ article }) => {
  const imageUrl = article.images; // Assuming 'imageUrl' is available in your article object

  return (
    <article className="article-card">
      <div className="article-container">
        {/* Image Section */}
        <div className="article-image-container">
          <img 
            src={imageUrl} 
            alt={`${article.content['heading']} image`} 
            loading="lazy" 
            className="article-image" 
          />
        </div>

        {/* Text Content Section */}
        <div className="article-text-container">
          <span className="article-label">{article.content['labels']}</span>
          <h2 className="article-heading">{article.content['heading']}</h2>
          <p className="article-context">{article.content['news-context']}</p>

          {/* Polls Section */}
          <div className="poll-section">
            {/* Poll 1 */}
            <div className="poll-container1 poll-left">
              <h3 className="poll-title">Poll 1:</h3>
              <p className="poll-description">
                {article.content['long-summary-left-leaning']}
              </p>
              <div className="poll-actions">
                <div className="poll-buttons">
                  <button className="poll-vote">
                    <i className="fas fa-arrow-up"></i>
                  </button>
                  <button className="poll-comment">
                    <i className="far fa-comment"></i>
                  </button>
                </div>
                <button className="poll-source-btn">Show Source</button>
              </div>
            </div>

            {/* Poll 2 */}
            <div className="poll-container2 poll-right">
              <h3 className="poll-title">Poll 2:</h3>
              <p className="poll-description">
                {article.content['short-summary-right-leaning']}
              </p>
              <div className="poll-actions">
                <div className="poll-buttons">
                  <button className="poll-vote">
                    <i className="fas fa-arrow-up"></i>
                  </button>
                  <button className="poll-comment">
                    <i className="far fa-comment"></i>
                  </button>
                </div>
                <button className="poll-source-btn">Show Source</button>
              </div>
            </div>
          </div>

          {/* Share and Save Buttons */}
          <div className="article-actions">
            <button className="article-action-btn">
              <i className="fas fa-share-alt"></i> Share
            </button>
            <button className="article-action-btn">
              <i className="fas fa-save"></i> Save
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ArticleCard;
