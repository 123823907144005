import React from 'react';
import Navbar from '../components/navbar';
import Banner from '../components/banner';
import Pagination from '../components/pagination';
import { base_url } from '../url';

const Home = () => {
  const dataUrl = `${base_url}/news/content/feed`;  // Your data URL here
  const articlesPerPage = 2;

  return (
    <div>
      <Navbar />
      <Banner />
      <h1>Articles</h1>
      <Pagination dataUrl={dataUrl} articlesPerPage={articlesPerPage} />
    </div>
  );
};

export default Home;
