import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import './styles/global.css';
import AppRoutes from './AppRoutes';

const App = () => {
  return (
    <Router>
      <div className="bg-gray-100 font-sans">
      <AppRoutes/>
      </div>
    </Router>
  );
};

export default App;
