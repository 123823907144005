// src/components/Banner.js
import React from 'react';

const Banner = () => (
  <div className="bg-red-600 texture mt-24 sm:mt-28 md:mt-32">
    <div className="container mx-auto px-4 overflow-x-auto">
      <ul className="flex justify-start md:justify-center space-x-4 sm:space-x-8 py-2">
        <li><a href="#" className="text-white hover:text-gray-200">Politics</a></li>
        <li><a href="#" className="text-white hover:text-gray-200">Crime</a></li>
        <li><a href="#" className="text-white hover:text-gray-200">International</a></li>
        <li><a href="#" className="text-white hover:text-gray-200">Trending</a></li>
        <li><a href="#" className="text-white hover:text-gray-200">Geopolitics</a></li>
      </ul>
    </div>
  </div>
);

export default Banner;
