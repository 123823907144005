import React, { useState } from "react";
import Cookies from "js-cookie"; // Import js-cookie
import '../styles/loginPage.css';
import '../styles/navbar.css'; // Import the CSS file
import { base_url } from "../url";
import { useNavigate, Link } from "react-router-dom";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();



  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    const payload = { email, password };

    try {
      const response = await fetch(`${base_url}/access/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      });

      const data = await response.json();
      if (response.ok) {
        // Save JWT token to cookies
        Cookies.set("token", data.token,); // Token expires in 1 day
        navigate('/');
        // onLogin(data.user); // Notify parent component
      } else {
        setError(data.message || "Login failed. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
      console.log(err)
    }
  };

  return (
    <div className="login">
    <div className="navbar-container"   >
    <Link to="/" className="navbar-logo">Bipolar Bharat</Link>
    </div>
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div className="input-group">
          <label className="label">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="input"
            required
          />
        </div>
        <div className="input-group">
          <label className="label">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            className="input"
            required
          />
        </div>
        {error && <p className="error">{error}</p>}
        <button type="submit">Login</button>
      </form>
    </div>
    </div>
  );
};

export default LoginPage;
