import { Link } from 'react-router-dom';
import { useState } from 'react';
import '../styles/navbar.css'; // Importing the CSS file
import Cookies from "js-cookie";
import { jwtDecode } from 'jwt-decode';

const checkLogin = () => {
  const token = Cookies.get("token");

  if (token) {
    try {
      const decodedToken = jwtDecode(token); // Decode the JWT token
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

      // Check if the token has expired
      if (decodedToken.exp && decodedToken.exp < currentTime) {
        console.log("Token has expired");
        return false; // Token is invalid due to expiration
      }

      return true; // User is logged in and token is valid
    } catch (error) {
      console.error("Invalid token:", error);
      return false; // Invalid token
    }
  }

  return false; // User is not logged in
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(checkLogin());





  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Logo */}
        <Link to="/" className="navbar-logo">Bipolar Bharat</Link>

        {/* Hamburger Icon */}
        <div className="navbar-hamburger" onClick={toggleMenu}>
          <svg className="navbar-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </div>

        {/* Links */}
        <ul className={`navbar-links ${isOpen ? "navbar-active" : ""}`}>
          <li><Link to="/" className="navbar-link">Home</Link></li>
          <li><Link to="/about" className="navbar-link">About</Link></li>
          <li><Link to="/contact" className="navbar-link">Contact</Link></li>
          <li>{isLoggedIn?(<Link to="/admin" className="navbar-link">Admin</Link>):(<Link to="/login" className="navbar-link">Login</Link>)}</li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
