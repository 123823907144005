import React from 'react';
import Navbar from '../components/navbar';
import Banner from '../components/banner';

const Contact = () => {
  return <div>
  <Navbar/>
  <Banner/>
  <h1>Contact</h1>
  </div>;
};

export default Contact;
