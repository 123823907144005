import React, { useState } from 'react';
import '../styles/form.css';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners'; // Import the ClipLoader component
import { base_url } from '../url';

const GenerateArticleForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        left_links: [''],
        right_links: [''],
        image_link: '',
    });
    const [loading, setLoading] = useState(false); // Add loading state

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleArrayChange = (e, field, index) => {
        const { value } = e.target;
        const updatedArray = [...formData[field]];
        updatedArray[index] = value;
        setFormData({ ...formData, [field]: updatedArray });
    };

    const addLinkField = (field) => {
        setFormData({ ...formData, [field]: [...formData[field], ''] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true before starting the request

        // Construct JSON payload in the desired format
        const payload = {
            title: formData.title,
            description: formData.description,
            left_links: formData.left_links.filter(link => link), // remove empty links
            right_links: formData.right_links.filter(link => link),
            image_links: formData.image_link,
            createdBy: "Admin",
        };
        console.log(payload);

        try {
            // Send payload to API endpoint
            const response = await fetch(`${base_url}/news/generate-content`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                // Navigate to a different page component upon success
                const responseData = await response.json();
                responseData["image_links"] = formData.image_link;
                console.log(responseData);

                navigate('/final-content-page', { state: { data: responseData } });
            } else {
                console.error("Failed to submit data.");
                alert("Failed to generate content")
            }
        } catch (error) {
            console.error("Error:", error);
            alert("Failed to generate content")
        } finally {
            setLoading(false); // Reset loading state after request is completed
        }
    };

    return (
        <div className="form_container">
            <h2>Create a New Article</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Title</label>
                    <input
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        placeholder="Enter article title"
                        required
                    />
                </div>
                <div>
                    <label>Description</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        rows="6"
                        placeholder="Write article content"
                        required
                    />
                </div>
                <div>
                    <label>Left Links</label>
                    {formData.left_links.map((link, index) => (
                        <input
                            key={index}
                            type="url"
                            value={link}
                            onChange={(e) => handleArrayChange(e, 'left_links', index)}
                            placeholder="Enter a left-leaning link"
                        />
                    ))}
                    <button type="button" onClick={() => addLinkField('left_links')}>
                        Add Left Link
                    </button>
                </div>
                <div>
                    <label>Right Links</label>
                    {formData.right_links.map((link, index) => (
                        <input
                            key={index}
                            type="url"
                            value={link}
                            onChange={(e) => handleArrayChange(e, 'right_links', index)}
                            placeholder="Enter a right-leaning link"
                        />
                    ))}
                    <button type="button" onClick={() => addLinkField('right_links')}>
                        Add Right Link
                    </button>
                </div>
                <div>
                    <label>Image Link</label>
                    <input
                        name="image_link"
                        type="text"
                        value={formData.image_link}
                        onChange={handleChange}
                        placeholder="Enter an image link in Base64 format"
                    />
                </div>
                
                {/* Display spinner while loading */}
                {loading ? (
                    <div className="loading-alert">
                    Generating content, please wait...
                    </div>
                ) : (
                    <button type="submit">Generate</button>
                )}
            </form>
        </div>
    );
};

export default GenerateArticleForm;
