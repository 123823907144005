import React from 'react';
import Navbar from '../components/navbar';
import Banner from '../components/banner';
import GenerateArticleForm from '../components/generateArticleForm';

const AdminPage = () => {

  return (
    <div>
      <Navbar />
      <Banner />
      <h1>Generate Article</h1>
      <GenerateArticleForm />
    </div>
  );
};

export default AdminPage;