import React, { useState, useEffect } from 'react';
import ArticleCard from './articleCard';
import '../styles/pageController.css'

const Pagination = ({ dataUrl, articlesPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadArticles = async (page) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${dataUrl}?page=${page}&limit=${articlesPerPage}`);
      const { totalItems, totalPages, currentPage, items } = await response.json();

      setItems(items);
      setTotalPages(totalPages);
      setCurrentPage(currentPage);
    } catch (error) {
      console.error('Error fetching articles:', error);
      setError('Failed to load articles. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadArticles(currentPage);
  }, [currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  return (
    <div>
      <div id="main-content">
        {isLoading ? (
          <p>Loading articles...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <div>
            {items.map(article => <ArticleCard key={article.id} article={article} />)}
          </div>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="pagination-container">
        <button
          id="prev-btn"
          onClick={handlePrevPage}
          disabled={currentPage === 1 || isLoading}
          className="pagination-button"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          id="next-btn"
          onClick={handleNextPage}
          disabled={currentPage === totalPages || isLoading}
          className="pagination-button"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
