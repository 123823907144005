import React from 'react';
import Navbar from '../components/navbar';
import Banner from '../components/banner';
import FinalContentForm from '../components/finalContentForm';
import { useLocation } from 'react-router-dom';

const FinalContentPage = () => {
    const location = useLocation();
    const data = location.state?.data ||{};
    console.log(data)
    // const { data } = location.state || {};

  return (
    <div>
      <Navbar />
      <Banner />
      <h1>Edit And Submit Final content</h1>
      <FinalContentForm data={data} />
    </div>
  );
};

export default FinalContentPage;