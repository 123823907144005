import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { base_url } from '../url';

const FinalContentForm = ({ data }) => {
    console.log(data)
    const navigate = useNavigate()
    // const location = useLocation();
    // const { data } = location.state || {}; // Get the passed state
    const [contentData, setContentData] = useState(data);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContentData(prev => ({
            ...prev,
            content: {
                ...prev.content,
                [name]: value,
            },
        }));
    };

    const handleArrayChange = (e, field, index) => {
        const { value } = e.target;
        const updatedArray = [...contentData.content[field]];
        updatedArray[index] = value;
        setContentData(prev => ({
            ...prev,
            content: {
                ...prev.content,
                [field]: updatedArray,
            },
        }));
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        // Construct the JSON payload
        const payload = {
            contentId: contentData.contentId,
            final_content: {
                labels: contentData.content.labels,
                heading: contentData.content.heading,
                "news-context": contentData.content["news-context"],
                "long-summary-left-leaning": contentData.content["long-summary-left-leaning"],
                "long-summary-right-leaning": contentData.content["long-summary-right-leaning"],
                "short-summary-left-leaning": contentData.content["short-summary-left-leaning"],
                "short-summary-right-leaning": contentData.content["short-summary-right-leaning"],
            },
            image_links: contentData.image_links,
        };

        console.log("Submitting Payload:", payload);

        try {
            // Call the POST API
            const response = await fetch(`${base_url}/admin/submit-content`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                console.log("Content submitted successfully.");
                navigate('/admin'); // Navigate to the /admin page
            } else {
                console.error("Failed to submit content.");
            }
        } catch (error) {
            console.error("Error while submitting content:", error);
        }
    };

    if (!contentData) {
        return <div>No content data available.</div>;
    }

    return (
        <div className="form_container">
            <h2>Edit Generated Content</h2>
            <form onSubmit={submitHandler}>
                <div>
                    <label>ContentID</label>
                    <input
                        type="text"
                        name="contentId"
                        value={contentData.contentId}
                        readOnly
                    />
                </div>
                <div>
                    <label>Heading</label>
                    <input
                        type="text"
                        name="heading"
                        value={contentData.content.heading}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <label>News Context</label>
                    <textarea
                        name="news-context"
                        value={contentData.content['news-context']}
                        onChange={handleChange}
                        rows="6"
                    />
                </div>
                <div>
                    <label>Labels</label>
                    {contentData.content.labels.map((label, index) => (
                        <input
                            key={index}
                            type="text"
                            value={label}
                            onChange={(e) => handleArrayChange(e, 'labels', index)}
                        />
                    ))}
                </div>
                <div>
                    <label>Long Summary (Left-Leaning)</label>
                    <textarea
                        name="long-summary-left-leaning"
                        value={contentData.content['long-summary-left-leaning']}
                        onChange={handleChange}
                        rows="6"
                    />
                </div>
                <div>
                    <label>Long Summary (Right-Leaning)</label>
                    <textarea
                        name="long-summary-right-leaning"
                        value={contentData.content['long-summary-right-leaning']}
                        onChange={handleChange}
                        rows="6"
                    />
                </div>
                <div>
                    <label>Short Summary (Left-Leaning)</label>
                    <textarea
                        name="short-summary-left-leaning"
                        value={contentData.content['short-summary-left-leaning']}
                        onChange={handleChange}
                        rows="4"
                    />
                </div>
                <div>
                    <label>Short Summary (Right-Leaning)</label>
                    <textarea
                        name="short-summary-right-leaning"
                        value={contentData.content['short-summary-right-leaning']}
                        onChange={handleChange}
                        rows="4"
                    />
                </div>
                <div>
                    <label>Image Link</label>
                    <input
                        name="image_links"
                        type="text"
                        value={contentData.image_links}
                        onChange={handleChange}
                        placeholder="Enter an image link in Base64 format"
                    />
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default FinalContentForm;
