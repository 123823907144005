import React from 'react';
import Navbar from '../components/navbar';
import Banner from '../components/banner';

const About = () => {
  return <div>
    <Navbar/>
    <Banner/>
    <h1>About page</h1>
    </div>;
};

export default About;
